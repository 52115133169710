import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { Icon } from '../../../common/icon'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Gewichtung2() {
  const question = {
    title: 'Gewichtung - 2. Teil',
    headline: 'Den 2.Teil hast du schon fast geschafft!',
    subline: (
      <>
        Bitte wähle hier noch <strong>zwei</strong> Dinge aus, die dir für den
        2.Teil besonders wichtig sind:
      </>
    ),
  }

  const answers = [
    {
      name: 'Wichtiger',
      id: 'arbeitsinhalte',
      value: 'Arbeitsinhalte',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'arbeitsumfang',
      value: 'Arbeitsumfang',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'arbeitsalltag',
      value: 'Arbeitsalltag',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'koerperlich-anpacken',
      value: 'körperlich anpacken',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'routine-oder-herausforderung',
      value: 'Routine vs. Herausforderung',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'medizintechnik',
      value: 'Medizintechnik',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'administration-organisation',
      value: 'Administration & Organisation',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'allgemeine-oder-spezielle-pflege',
      value: 'allgemeine vs. spezielle Pflege',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'rotationsmoeglichkeiten',
      value: 'Rotationsmöglichkeiten',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'fort-weiterbildung',
      value: 'Fort- und Weiterbildung',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'karriere',
      value: 'Karriere',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-purple">
        <Navigation />

        <section className="quiz pt-0">
          <div className="row text-center">
            <img
              className="mx-auto"
              data-src="/img/handlettering/handlettering-yes-1.svg"
              src="/img/handlettering/handlettering-yes-1.svg"
              alt="Yes!"
              width="400"
            />
            <MatchingQuestion
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="multi"
              answers={answers}
              color="turkis"
              limited
              index={'g2'}
            />
          </div>
        </section>

        <MatchingNavigation
          color="white"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-21`}
          prev={`${AREA_CARE_PATH}/spielen/frage-20`}
          index={'g2'}
        />

        <Icon position="1" name="pills" />
        <Icon position="2" name="bandage" />
        <Icon position="3" name="signpost" />
        <Icon position="4" name="weightlifter" />
      </main>
    </>
  )
}
